import { motion } from "framer-motion";
import React, { useState } from "react";

const CONTACT_REASONS = [
  {
    id: "demo",
    icon: "🎯",
    title: "Demo anfragen",
    subject: "Demo-Anfrage für GastroEmpire",
    description: "Lernen Sie GastroEmpire in einer persönlichen Demo kennen",
    placeholder:
      "Beschreiben Sie kurz Ihr Restaurant und welche Funktionen Sie besonders interessieren. Gerne vereinbaren wir einen Termin für eine persönliche Demo.",
    suggestedPoints: [
      "Art des Restaurants",
      "Anzahl der Filialen",
      "Aktuelle Herausforderungen",
      "Gewünschter Demo-Termin",
    ],
  },
  {
    id: "pricing",
    icon: "💰",
    title: "Preisanfrage",
    subject: "Preisanfrage für GastroEmpire",
    description: "Erhalten Sie ein individuelles Angebot",
    placeholder:
      "Teilen Sie uns mit, welche Features Sie benötigen und wie groß Ihr Restaurant ist.",
    suggestedPoints: [
      "Anzahl der Filialen",
      "Gewünschte Features",
      "Durchschnittliche Bestellungen pro Tag",
      "Aktuelle Software-Lösung",
    ],
  },
  {
    id: "support",
    icon: "🛟",
    title: "Support",
    subject: "Support-Anfrage",
    description: "Technische Hilfe & Unterstützung",
    placeholder:
      "Beschreiben Sie Ihr Anliegen möglichst genau. Je detaillierter Ihre Beschreibung, desto schneller können wir Ihnen helfen.",
    suggestedPoints: [
      "Betroffene Funktion",
      "Fehlerbeschreibung",
      "Wann tritt der Fehler auf?",
      "Browser/Gerät",
    ],
  },
  {
    id: "feedback",
    icon: "💡",
    title: "Feedback & Vorschläge",
    subject: "Feedback zu GastroEmpire",
    description: "Teilen Sie uns Ihre Ideen mit",
    placeholder:
      "Wir freuen uns über Ihr Feedback! Egal ob Verbesserungsvorschlag oder neue Feature-Idee.",
    suggestedPoints: [
      "Was gefällt Ihnen?",
      "Was könnte besser sein?",
      "Welche Features vermissen Sie?",
      "Wie lange nutzen Sie GastroEmpire?",
    ],
  },
  {
    id: "other",
    icon: "✉️",
    title: "Sonstiges",
    subject: "",
    description: "Andere Anfragen",
    placeholder:
      "Ihr Anliegen passt in keine der anderen Kategorien? Beschreiben Sie es hier.",
    suggestedPoints: [],
  },
];

const ContactForm = () => {
  const [selectedReason, setSelectedReason] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);

  const validateForm = () => {
    const newErrors = {};
    if (formData.name.length < 2 || formData.name.length > 100) {
      newErrors.name = "Name muss zwischen 2 und 100 Zeichen lang sein";
    }
    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = "Bitte geben Sie eine gültige E-Mail-Adresse ein";
    }
    if (formData.subject.length < 2 || formData.subject.length > 200) {
      newErrors.subject = "Betreff muss zwischen 2 und 200 Zeichen lang sein";
    }
    if (formData.message.length < 10 || formData.message.length > 5000) {
      newErrors.message =
        "Nachricht muss zwischen 10 und 5000 Zeichen lang sein";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const response = await fetch("https://api.gastroempire.de/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 429) {
        setSubmitStatus({
          type: "error",
          message: "Zu viele Anfragen. Bitte versuchen Sie es später erneut.",
        });
        return;
      }

      if (!response.ok) throw new Error("Fehler beim Senden der Nachricht");

      const data = await response.json();
      setSubmitStatus({
        type: "success",
        message: data.message || "Ihre Nachricht wurde erfolgreich gesendet!",
      });
      setSelectedReason(null);
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message:
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReasonSelect = (reason) => {
    setSelectedReason(reason);
    setFormData((prev) => ({
      ...prev,
      subject: reason.subject,
    }));
    setShowSuggestions(true);
  };

  if (!selectedReason) {
    return (
      <div className="space-y-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {CONTACT_REASONS.map((reason) => (
            <motion.button
              key={reason.id}
              onClick={() => handleReasonSelect(reason)}
              className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 flex flex-col items-center text-center group relative overflow-hidden"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <span className="text-4xl mb-3 relative">{reason.icon}</span>
              <h3 className="text-lg font-semibold text-gray-900 relative">
                {reason.title}
              </h3>
              <p className="text-sm text-gray-600 mt-2 relative">
                {reason.description}
              </p>
            </motion.button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <button
          onClick={() => setSelectedReason(null)}
          className="text-blue-600 hover:text-blue-700 font-medium flex items-center group"
        >
          <svg
            className="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
          Zurück zur Auswahl
        </button>
        <div className="flex items-center">
          <span className="text-2xl mr-2">{selectedReason.icon}</span>
          <h3 className="text-lg font-semibold text-gray-900">
            {selectedReason.title}
          </h3>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              className={`mt-1 block w-full rounded-md shadow-sm ${
                errors.name ? "border-red-300 ring-red-500" : "border-gray-300"
              } focus:border-blue-500 focus:ring-blue-500 transition-colors`}
              required
            />
            {errors.name && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-1 text-sm text-red-600"
              >
                {errors.name}
              </motion.p>
            )}
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              E-Mail
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
              className={`mt-1 block w-full rounded-md shadow-sm ${
                errors.email ? "border-red-300 ring-red-500" : "border-gray-300"
              } focus:border-blue-500 focus:ring-blue-500 transition-colors`}
              required
            />
            {errors.email && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-1 text-sm text-red-600"
              >
                {errors.email}
              </motion.p>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="subject"
            className="block text-sm font-medium text-gray-700"
          >
            Betreff
          </label>
          <input
            type="text"
            id="subject"
            value={formData.subject}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, subject: e.target.value }))
            }
            className={`mt-1 block w-full rounded-md shadow-sm ${
              errors.subject ? "border-red-300 ring-red-500" : "border-gray-300"
            } focus:border-blue-500 focus:ring-blue-500 transition-colors`}
            required
          />
          {errors.subject && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-1 text-sm text-red-600"
            >
              {errors.subject}
            </motion.p>
          )}
        </div>

        <div>
          <div className="flex justify-between items-center">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              Nachricht
            </label>
            {selectedReason.suggestedPoints.length > 0 && (
              <button
                type="button"
                onClick={() => setShowSuggestions(!showSuggestions)}
                className="text-sm text-blue-600 hover:text-blue-700"
              >
                {showSuggestions
                  ? "Vorschläge ausblenden"
                  : "Vorschläge einblenden"}
              </button>
            )}
          </div>
          <div className="mt-1 relative">
            <textarea
              id="message"
              rows={8}
              value={formData.message}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, message: e.target.value }))
              }
              placeholder={selectedReason.placeholder}
              className={`block w-full rounded-md shadow-sm ${
                errors.message
                  ? "border-red-300 ring-red-500"
                  : "border-gray-300"
              } focus:border-blue-500 focus:ring-blue-500 transition-colors`}
              required
            />
            {errors.message && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-1 text-sm text-red-600"
              >
                {errors.message}
              </motion.p>
            )}
          </div>
          {showSuggestions && selectedReason.suggestedPoints.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-4 bg-gray-50 rounded-lg p-4"
            >
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Hilfreiche Punkte für Ihre Nachricht:
              </h4>
              <ul className="list-disc list-inside space-y-1">
                {selectedReason.suggestedPoints.map((point, index) => (
                  <li key={index} className="text-sm text-gray-600">
                    {point}
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </div>

        {submitStatus && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className={`p-4 rounded-md ${
              submitStatus.type === "success"
                ? "bg-green-50 text-green-800 border border-green-200"
                : "bg-red-50 text-red-800 border border-red-200"
            }`}
          >
            {submitStatus.message}
          </motion.div>
        )}

        <motion.button
          type="submit"
          disabled={isSubmitting}
          className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors ${
            isSubmitting ? "opacity-75 cursor-not-allowed" : ""
          }`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          {isSubmitting ? (
            <span className="flex items-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Wird gesendet...
            </span>
          ) : (
            "Nachricht senden"
          )}
        </motion.button>
      </form>
    </motion.div>
  );
};

export default ContactForm;
