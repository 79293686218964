import { Disclosure, Transition } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { FiChevronDown, FiCoffee, FiUsers } from "react-icons/fi";

const FAQSection = () => {
  const [activeTab, setActiveTab] = useState("gastro");

  const faqsGastro = [
    {
      question: "Wie starte ich mit Gastroempire?",
      answer:
        "Der Start ist einfach: Kontaktieren Sie uns für ein unverbindliches Beratungsgespräch. Wir erstellen Ihnen ein individuelles Angebot und richten Ihr persönliches Bestellsystem ein. Testen Sie Gastroempire einen Monat lang kostenlos - überzeugen Sie sich selbst von den Vorteilen eines modernen Online-Bestellsystems.",
    },
    {
      question: "Welche Bezahlmöglichkeiten kann ich meinen Kunden anbieten?",
      answer:
        "Wir setzen auf PayPal als sichere und bewährte Zahlungslösung. Ihre Kunden können damit flexibel wählen: PayPal-Guthaben, Lastschrift, alle gängigen Kreditkarten oder Rechnungskauf über PayPal. Die Zahlungen werden sofort und sicher abgewickelt.",
    },
    {
      question: "Wie werden eingehende Bestellungen verarbeitet?",
      answer:
        "Bestellungen werden in Echtzeit in Ihrem übersichtlichen Admin-Dashboard angezeigt. Zusätzlich bieten wir einen WLAN-Thermodrucker zur Miete an, der neue Bestellungen automatisch ausdruckt - ganz ohne PC oder Tablet. Der Drucker arbeitet vollautomatisch und ist sofort einsatzbereit. So haben Sie alle Bestellungen immer im Blick und können sich auf das Wesentliche konzentrieren: Ihre Gäste.",
    },
  ];

  const faqsCustomer = [
    {
      question: "Wie funktioniert die Online-Bestellung?",
      answer:
        "Die Bestellung ist kinderleicht: Besuchen Sie die Restaurant-Webseite, wählen Sie aus der übersichtlichen Speisekarte Ihre Lieblingsspeisen aus und legen Sie diese in den Warenkorb. Dank intelligenter Filterfunktionen finden Sie schnell genau das, worauf Sie Appetit haben. Nach wenigen Klicks ist Ihre Bestellung auf dem Weg.",
    },
    {
      question: "Wie werde ich über den Status meiner Bestellung informiert?",
      answer:
        "Nach Ihrer Bestellung erhalten Sie sofort eine Bestätigungs-E-Mail mit Ihrer persönlichen Bestellnummer. Damit können Sie jederzeit den aktuellen Status Ihrer Bestellung einsehen und wissen genau, wann Sie Ihr Essen erwarten können.",
    },
    {
      question:
        "Wie finde ich Gerichte, die zu meinen Ernährungsgewohnheiten passen?",
      answer:
        "Jedes Gericht ist mit detaillierten Informationen zu Allergenen und Inhaltsstoffen gekennzeichnet. Sie können individuelle Anmerkungen zu Ihrer Bestellung hinzufügen, zum Beispiel für besondere Zubereitungswünsche. So stellen wir sicher, dass Sie genau das bekommen, was Sie möchten.",
    },
  ];

  const TabButton = ({ id, icon: Icon, label }) => (
    <motion.button
      className={`flex items-center space-x-3 px-6 py-3 rounded-full transition-all ${
        activeTab === id
          ? "bg-blue-600 text-white shadow-lg scale-105"
          : "bg-white text-gray-600 hover:bg-gray-50 hover:scale-102"
      }`}
      onClick={() => setActiveTab(id)}
      whileHover={{ scale: activeTab === id ? 1.05 : 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <Icon
        className={`w-5 h-5 ${
          activeTab === id ? "text-white" : "text-blue-600"
        }`}
      />
      <span className="font-medium">{label}</span>
    </motion.button>
  );

  const FAQItem = ({ item }) => (
    <Disclosure as="div" className="mt-6 first:mt-0">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full px-6 py-4 text-lg font-medium text-left text-gray-900 bg-white/50 rounded-xl hover:bg-white/80 transition-all duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-opacity-75 border border-gray-100 shadow-sm hover:shadow">
            <span className="pr-6">{item.question}</span>
            <FiChevronDown
              className={`${
                open ? "transform rotate-180" : ""
              } w-5 h-5 text-blue-600 flex-shrink-0 transition-transform duration-200`}
            />
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-98 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-150 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-98 opacity-0"
          >
            <Disclosure.Panel className="px-6 pt-6 pb-4 text-gray-600 text-lg leading-relaxed">
              {item.answer}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );

  return (
    <section className="py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-white opacity-80"></div>
      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-900">
              Häufig gestellte Fragen
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
              Finden Sie Antworten auf die wichtigsten Fragen zu Gastroempire.
              Wählen Sie Ihre Perspektive und erfahren Sie mehr.
            </p>
          </motion.div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center gap-4 mb-12">
          <TabButton id="gastro" icon={FiCoffee} label="Für Gastronomen" />
          <TabButton id="customer" icon={FiUsers} label="Für Kunden" />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="max-w-3xl mx-auto bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden border border-gray-100"
        >
          <div className="p-8 sm:p-10">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                {activeTab === "gastro" ? (
                  <>
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">
                      Für Gastronomen
                    </h3>
                    {faqsGastro.map((item, index) => (
                      <FAQItem key={index} item={item} />
                    ))}
                  </>
                ) : (
                  <>
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">
                      Für Kunden
                    </h3>
                    {faqsCustomer.map((item, index) => (
                      <FAQItem key={index} item={item} />
                    ))}
                  </>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default FAQSection;
