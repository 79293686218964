import React from "react";

const ImprintAndPrivacy = () => (
  <div className="space-y-8">
    <section>
      <h2 className="text-2xl font-bold mb-4">Impressum</h2>
      <p>Angaben gemäß § 5 TMG</p>
      <div className="space-y-1 mt-4">
        <p className="font-semibold">Wolf Code Software Solutions</p>
        <p>Inhaber: Andreas Wolf</p>
        <p>Markgrafenstr. 21</p>
        <p>96364 Marktrodach</p>
      </div>

      <h3 className="text-xl font-semibold mt-6 mb-2">Kontakt</h3>
      <div className="space-y-1">
        <p>E-Mail: <a href="mailto:info@wolf-code.de" className="text-blue-600 hover:text-blue-800">info@wolf-code.de</a></p>
      </div>

      <h3 className="text-xl font-semibold mt-6 mb-2">Umsatzsteuer-ID</h3>
      <div className="space-y-1">
        <p>USt-IdNr: DE351209714</p>
      </div>

      <h3 className="text-xl font-semibold mt-6 mb-2">
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
      </h3>
      <div className="space-y-1">
        <p>Wolf Code Software Solutions</p>
        <p>Andreas Wolf</p>
        <p>Markgrafenstr. 21</p>
        <p>96364 Marktrodach</p>
      </div>

      <h3 className="text-xl font-semibold mt-4 mb-2">EU-Streitschlichtung</h3>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/consumers/odr
        </a>
        . Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>
    </section>

    <section>
      <h2 className="text-2xl font-bold mb-4">Datenschutzerklärung</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">
        1. Datenschutz auf einen Blick
      </h3>
      <h4 className="text-lg font-semibold mt-3 mb-1">Allgemeine Hinweise</h4>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können.
      </p>

      <h4 className="text-lg font-semibold mt-3 mb-1">
        Datenerfassung auf dieser Website
      </h4>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
        Website entnehmen.
      </p>

      <h4 className="text-lg font-semibold mt-3 mb-1">
        Wie erfassen wir Ihre Daten?
      </h4>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>

      <h4 className="text-lg font-semibold mt-3 mb-1">
        Wofür nutzen wir Ihre Daten?
      </h4>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>

      <h4 className="text-lg font-semibold mt-3 mb-1">
        Welche Rechte haben Sie bezüglich Ihrer Daten?
      </h4>
      <p>
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
        Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">
        2. Allgemeine Hinweise und Pflichtinformationen
      </h3>
      <h4 className="text-lg font-semibold mt-3 mb-1">Datenschutz</h4>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>

      <h4 className="text-lg font-semibold mt-3 mb-1">
        Hinweis zur verantwortlichen Stelle
      </h4>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <div className="space-y-1 mt-2">
        <p>Wolf Code Software Solutions</p>
        <p>Inhaber: Andreas Wolf</p>
        <p>Markgrafenstr. 21</p>
        <p>96364 Marktrodach</p>
        <p>E-Mail: <a href="mailto:info@wolf-code.de" className="text-blue-600 hover:text-blue-800">info@wolf-code.de</a></p>
      </div>

      <h3 className="text-xl font-semibold mt-4 mb-2">
        3. Datenerfassung auf dieser Website
      </h3>
      <h4 className="text-lg font-semibold mt-3 mb-1">Kontaktformular</h4>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
    </section>
  </div>
);

export default ImprintAndPrivacy;
