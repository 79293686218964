import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const FeatureSection = ({ title, description, imageSrc }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-50px" }}
      transition={{ duration: 0.5 }}
    >
      <div
        className="overflow-hidden rounded-lg mb-6 aspect-[16/10] cursor-zoom-in relative"
        onClick={() => setIsLightboxOpen(true)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setIsLightboxOpen(true);
          }
        }}
      >
        <div className="relative w-full h-full bg-gray-50 overflow-hidden">
          <AnimatePresence initial={false}>
            {!isHovered ? (
              <motion.div
                key="normal"
                className="w-full h-full"
                initial={{ scale: 1.2 }}
                animate={{
                  scale: 1,
                  x: 0,
                }}
                transition={{ duration: 0.4 }}
              >
                <img
                  src={imageSrc}
                  alt={title}
                  className="w-full h-full object-contain"
                  loading="lazy"
                />
              </motion.div>
            ) : (
              <motion.div
                key="hover"
                className="w-full h-full"
                initial={{ scale: 1 }}
                animate={{
                  scale: 1.2,
                  x: [-10, 10, -10],
                }}
                transition={{
                  scale: { duration: 0.4 },
                  x: {
                    duration: 4,
                    repeat: Infinity,
                    ease: "linear",
                    repeatType: "reverse",
                  },
                }}
              >
                <img
                  src={imageSrc}
                  alt={title}
                  className="w-full h-full object-contain"
                  loading="lazy"
                />
              </motion.div>
            )}
          </AnimatePresence>
          <div
            className={`absolute inset-0 bg-black transition-all duration-300 flex items-center justify-center ${
              isHovered ? "bg-opacity-10" : "bg-opacity-0"
            }`}
          >
            <div
              className={`transition-all duration-300 transform ${
                isHovered ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
            >
              <svg
                className="w-10 h-10 text-white drop-shadow-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v6m4-3H6"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <h3 className="text-2xl font-bold mb-3 text-gray-800">{title}</h3>
        <p className="text-gray-600 leading-relaxed">{description}</p>
      </motion.div>

      <Lightbox
        open={isLightboxOpen}
        close={() => setIsLightboxOpen(false)}
        slides={[{ src: imageSrc, alt: title }]}
        carousel={{
          finite: true,
        }}
        animation={{
          swipe: 250,
        }}
        className="bg-black/90"
      />
    </motion.div>
  );
};

export default FeatureSection;
