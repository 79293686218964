import { motion } from "framer-motion";
import React from "react";
import { HiMenu } from "react-icons/hi";
import ContactForm from "./components/ContactForm";
import DeviceSlider from "./components/DeviceSlider";
import MobileMenu from "./components/MobileMenu";
import FAQSection from "./FAQSection";
import FeatureSection from "./FeatureSection";
import Footer from "./Footer";
import ImprintAndPrivacy from "./ImprintAndPrivacy";
import logo from "./logo.svg";

const LandingPage = () => {
  const [showImprint, setShowImprint] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const features = [
    {
      title: "Umfassende Bestellverwaltung",
      description:
        "Behalten Sie den Überblick über alle eingehenden Bestellungen. Verwalten Sie Ihre Bestellungen einfach und effizient in Echtzeit.",
      imageSrc: "/images/Bestellverwaltung.webp",
    },
    {
      title: "Flexible Lieferzonen",
      description:
        "Definieren Sie individuelle Liefergebiete mit verschiedenen Mindestbestellwerten und Liefergebühren.",
      imageSrc: "/images/Lieferzonen.webp",
    },
    {
      title: "Automatischer Thermodruck",
      description:
        "Eingehende Bestellungen werden in Echtzeit auf unseren speziellen WLAN-Thermodruckern ausgegeben. Die Drucker arbeiten komplett eigenständig - Sie benötigen weder PC noch Tablet zur Bestellannahme.",
      imageSrc: "/images/Thermodrucker.webp",
    },
    {
      title: "Professionelle Produktverwaltung",
      description:
        "Erstellen und verwalten Sie Ihre Produkte, Kategorien und Optionen mit wenigen Klicks. Fügen Sie Bilder und detaillierte Beschreibungen hinzu.",
      imageSrc: "/images/Produktverwaltung.webp",
    },
    {
      title: "Individuell anpassbar",
      description:
        "Gestalten Sie Ihren Onlineshop nach Ihren Vorstellungen. Wählen Sie eigene Hintergrundbilder, Logos, Farben und Texte - für einen einzigartigen Auftritt.",
      imageSrc: "/images/IndividuellGestaltbar.webp",
    },
    {
      title: "Detaillierte Statistiken",
      description:
        "Behalten Sie den Überblick über Ihre Umsätze, beliebtesten Produkte und Bestelltrends mit übersichtlichen Auswertungen.",
      imageSrc: "/images/Statistiken.webp",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
      <header className="bg-white/95 backdrop-blur-sm shadow-md py-4 sticky top-0 z-50 transition-all duration-300 w-full">
        <div className="w-full">
          <div className="flex justify-between items-center w-full px-1">
            <div className="flex items-center gap-2">
              <img src={logo} alt="GastroEmpire Logo" className="h-12 w-auto" />
            </div>
            <nav className="hidden lg:block">
              <ul className="flex space-x-6 items-center">
                <li>
                  <a
                    href="#features"
                    className="text-gray-600 hover:text-blue-600 font-medium transition duration-300"
                  >
                    Features
                  </a>
                </li>
                <li>
                  <a
                    href="#faq"
                    className="text-gray-600 hover:text-blue-600 font-medium transition duration-300"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    className="text-gray-600 hover:text-blue-600 font-medium transition duration-300"
                  >
                    Kontakt
                  </a>
                </li>
                <li>
                  <motion.a
                    href="https://dashboard.gastroempire.de"
                    className="ml-4 inline-block bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition duration-300 font-medium shadow-sm hover:shadow-md"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Login
                  </motion.a>
                </li>
              </ul>
            </nav>
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 lg:hidden hover:bg-gray-100 rounded-lg transition duration-300"
            >
              <HiMenu className="w-6 h-6 text-gray-600" />
            </button>
          </div>
        </div>
      </header>

      <MobileMenu isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen}>
        <ul className="flex flex-col space-y-4">
          <li>
            <a
              href="#features"
              className="text-gray-600 hover:text-blue-600 font-medium block py-2 transition duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Features
            </a>
          </li>
          <li>
            <a
              href="#faq"
              className="text-gray-600 hover:text-blue-600 font-medium block py-2 transition duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              FAQ
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="text-gray-600 hover:text-blue-600 font-medium block py-2 transition duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Kontakt
            </a>
          </li>
          <li className="pt-4">
            <a
              href="https://dashboard.gastroempire.de"
              className="w-full inline-block bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition duration-300 font-medium text-center"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Login
            </a>
          </li>
        </ul>
      </MobileMenu>

      <main>
        <section className="relative min-h-[85vh] flex items-center justify-center py-20 text-center w-full">
          <div className="absolute inset-0 z-0">
            <img
              src="/images/hero-background.jpg"
              alt="Modern restaurant interior"
              className="w-full h-full object-cover"
              loading="eager"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-black/5 to-transparent"></div>
          </div>
          <div className="max-w-[2000px] mx-auto relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <motion.div
                className="flex items-center justify-center mb-12"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <div className="bg-white/80 backdrop-blur-sm px-8 py-6 rounded-xl">
                  <img
                    src={logo}
                    alt="GastroEmpire Logo"
                    className="w-[40rem] h-auto"
                  />
                </div>
              </motion.div>
              <motion.h2
                className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 text-white leading-tight max-w-4xl mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                Revolutionieren Sie Ihr Restaurantgeschäft
              </motion.h2>
              <motion.p
                className="text-xl sm:text-2xl mb-12 text-gray-200 max-w-2xl mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.8 }}
              >
                Ihre All-in-One-Lösung für Online-Bestellungen in der
                Gastronomie
              </motion.p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6">
                <motion.a
                  href="#contact"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full sm:w-auto inline-block bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300 shadow-lg hover:shadow-xl"
                >
                  Kostenlose Demo anfordern
                </motion.a>
                <motion.a
                  href="#features"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full sm:w-auto inline-block bg-white/10 backdrop-blur-sm text-white border-2 border-white/20 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-white/20 transition duration-300 shadow-lg hover:shadow-xl"
                >
                  Mehr erfahren
                </motion.a>
              </div>
            </motion.div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent z-10"></div>
        </section>

        <section id="features" className="py-24 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <h2 className="text-4xl font-bold mb-6 text-gray-900">
                  Unsere Features
                </h2>
                <p className="text-xl text-gray-600 leading-relaxed">
                  Entdecken Sie alle Werkzeuge, die Sie für ein erfolgreiches
                  Online-Bestellsystem benötigen. Von der Bestellannahme bis zur
                  Auslieferung - alles in einer Lösung.
                </p>
              </motion.div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-10">
              {features.map((feature, index) => (
                <FeatureSection key={index} {...feature} />
              ))}
            </div>

            <div className="mt-16 text-center">
              <motion.a
                href="#contact"
                className="inline-flex items-center text-blue-600 hover:text-blue-700 font-semibold group"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>Alle Features entdecken</span>
                <svg
                  className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </motion.a>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-transparent"></div>
          <div className="container mx-auto px-4 relative">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <h2 className="text-4xl font-bold mb-6 text-gray-900">
                  Gastroempire in Aktion
                </h2>
                <p className="text-xl text-gray-600 leading-relaxed">
                  Erleben Sie unsere intuitive Benutzeroberfläche auf allen
                  Geräten. Von der mobilen Bestellung bis zur
                  Desktop-Verwaltung.
                </p>
              </motion.div>
            </div>

            <div className="flex flex-wrap justify-center items-start gap-8 lg:gap-12">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              >
                <DeviceSlider
                  images={[
                    {
                      src: "/images/Handyansicht.webp",
                      alt: "Gastroempire mobile Ansicht",
                    },
                    {
                      src: "/images/Handyansicht2.webp",
                      alt: "Gastroempire mobile Ansicht 2",
                    },
                    {
                      src: "/images/Handyansicht3.webp",
                      alt: "Gastroempire mobile Ansicht 3",
                    },
                  ]}
                  title="Mobile Ansicht"
                />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3"
              >
                <DeviceSlider
                  images={[
                    {
                      src: "/images/Tabletsicht.webp",
                      alt: "Gastroempire Tablet Ansicht",
                    },
                    {
                      src: "/images/Tabletsicht2.webp",
                      alt: "Gastroempire Tablet Ansicht 2",
                    },
                    {
                      src: "/images/Tabletsicht3.webp",
                      alt: "Gastroempire Tablet Ansicht 3",
                    },
                  ]}
                  title="Tablet Ansicht"
                />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.4 }}
                className="w-full md:w-2/3 lg:w-1/2"
              >
                <DeviceSlider
                  images={[
                    {
                      src: "/images/PCKundensicht.webp",
                      alt: "Gastroempire Desktop Ansicht",
                    },
                    {
                      src: "/images/PCKundensicht2.webp",
                      alt: "Gastroempire Desktop Ansicht 2",
                    },
                    {
                      src: "/images/PCKundensicht3.webp",
                      alt: "Gastroempire Desktop Ansicht 3",
                    },
                  ]}
                  title="Desktop Ansicht"
                />
              </motion.div>
            </div>
          </div>
        </section>

        <section id="faq" className="py-20 bg-gray-100">
          <FAQSection />
        </section>

        <section id="contact" className="py-24 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-blue-50/30"></div>
          <div className="container mx-auto px-4 relative">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <h2 className="text-4xl font-bold mb-6 text-gray-900">
                  Kontaktieren Sie uns
                </h2>
                <p className="text-xl text-gray-600 leading-relaxed mb-8">
                  Haben Sie Fragen zu Gastroempire? Unser Team steht Ihnen gerne
                  zur Verfügung.
                </p>
              </motion.div>
            </div>

            <div className="max-w-2xl mx-auto bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl p-8 sm:p-10 border border-gray-100">
              <ContactForm />
            </div>
          </div>
        </section>
      </main>

      <Footer setShowImprint={setShowImprint} />

      {showImprint && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-2xl max-h-[80vh] overflow-y-auto">
            <ImprintAndPrivacy />
            <button
              onClick={() => setShowImprint(false)}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
            >
              Schließen
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
