import { motion } from "framer-motion";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const DeviceSlider = React.memo(function DeviceSlider({
  images,
  title,
  className,
}) {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
    adaptiveHeight: true,
    beforeChange: (current, next) => setLightboxIndex(next),
    customPaging: (i) => (
      <button
        className={`w-2 h-2 rounded-full transition-all duration-300 bg-white/80 hover:bg-blue-600`}
        aria-label={`Gehe zu Bild ${i + 1}`}
      />
    ),
  };

  if (!images || images.length === 0) {
    return null;
  }

  const lightboxImages = images.map((img) => ({
    src: img.src,
    alt: img.alt,
  }));

  return (
    <div className={className}>
      <div className="relative group">
        <div className="absolute -inset-2 bg-gradient-to-r from-blue-600 to-blue-400 rounded-xl blur-xl opacity-20 group-hover:opacity-30 transition duration-500" />
        <div className="relative overflow-hidden rounded-lg">
          <Slider {...settings} className="device-slider">
            {images.map((image, index) => (
              <div
                key={index}
                className="relative cursor-zoom-in"
                style={{ aspectRatio: "16/9" }}
                onClick={() => {
                  setLightboxIndex(index);
                  setLightboxOpen(true);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    setLightboxIndex(index);
                    setLightboxOpen(true);
                  }
                }}
              >
                <motion.img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-full object-cover shadow-xl transform group-hover:scale-[1.02] transition duration-500"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
                <div className="absolute inset-0 bg-black/0 hover:bg-black/10 transition-colors duration-300 flex items-center justify-center">
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <svg
                      className="w-8 h-8 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v6m4-3H6"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {title && (
        <p className="mt-4 text-center text-gray-600 font-medium">{title}</p>
      )}

      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        index={lightboxIndex}
        slides={lightboxImages}
        render={{
          buttonPrev: images.length <= 1 ? () => null : undefined,
          buttonNext: images.length <= 1 ? () => null : undefined,
        }}
      />
    </div>
  );
});

export default DeviceSlider;
