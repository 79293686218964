import { QRCodeSVG } from "qrcode.react";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
// Beispiel: Importiere Dein Logo (Pfad anpassen)
import logo from "../logo.svg";

// PrintableContent als separater Component mit forwardRef
const PrintableContent = React.forwardRef((props, ref) => {
  const pageWidth = 297;
  const pageHeight = 210;
  const margin = 15;

  const commonStyles = {
    backgroundColor: "white",
    background: "linear-gradient(to bottom right, #e6f0ff, white, #e6f0ff)",
    padding: `10mm 0 ${margin}mm 0`,
    height: `${pageHeight}mm`,
    width: `${pageWidth}mm`,
    boxSizing: "border-box",
    position: "relative",
    "@media print": {
      background:
        "linear-gradient(to bottom right, #f0f7ff 0%, white 50%, #f0f7ff 100%) !important",
      WebkitPrintColorAdjust: "exact",
      colorAdjust: "exact",
    },
  };

  const panelStyle = {
    width: "50%",
    height: "100%",
    padding: "0 15mm",
    boxSizing: "border-box",
    position: "relative",
  };

  const verticalLine = {
    content: '""',
    position: "absolute",
    top: `${margin}mm`,
    bottom: `${margin}mm`,
    left: "50%",
    width: "1px",
    borderLeft: "2px dashed #ccc",
  };

  const headingStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1a365d",
    marginBottom: "15px",
  };

  const subHeadingStyle = {
    fontSize: "20px",
    fontWeight: "600",
    color: "#2b6cb0",
    marginBottom: "12px",
  };

  const imageStyle = {
    width: "100%",
    height: "120px",
    objectFit: "cover",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
  };

  const checkmarkStyle = {
    color: "#2b6cb0",
    marginRight: "8px",
    fontSize: "18px",
  };

  return (
    <div ref={ref}>
      {/* Erste Seite - Cover & Rückseite */}
      <div
        style={{ ...commonStyles, pageBreakAfter: "always" }}
        className="page-background"
      >
        <div style={{ display: "flex", height: "100%" }}>
          {/* Linkes Panel: Back Cover */}
          <div style={panelStyle}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2 style={subHeadingStyle}>Warum GastroEmpire?</h2>
                <img
                  src="/images/hero-background.jpg"
                  alt="Restaurant Impression"
                  style={{
                    ...imageStyle,
                    marginBottom: "20px",
                    height: "140px",
                    objectPosition: "center 30%",
                  }}
                />
                <ul
                  style={{
                    listStyle: "none",
                    padding: 0,
                    marginBottom: "20px",
                  }}
                >
                  <li
                    style={{
                      marginBottom: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={checkmarkStyle}>✓</span>
                    <div>
                      <strong style={{ color: "#2d3748" }}>
                        Professionelle Lösung
                      </strong>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#4a5568",
                          margin: "2px 0 0 0",
                        }}
                      >
                        Individuell anpassbare Software nach Ihren Bedürfnissen
                      </p>
                    </div>
                  </li>
                  <li
                    style={{
                      marginBottom: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={checkmarkStyle}>✓</span>
                    <div>
                      <strong style={{ color: "#2d3748" }}>
                        Schnelle Integration
                      </strong>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#4a5568",
                          margin: "2px 0 0 0",
                        }}
                      >
                        In 24 Stunden einsatzbereit
                      </p>
                    </div>
                  </li>
                  <li
                    style={{
                      marginBottom: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={checkmarkStyle}>✓</span>
                    <div>
                      <strong style={{ color: "#2d3748" }}>
                        Persönliche Betreuung
                      </strong>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#4a5568",
                          margin: "2px 0 0 0",
                        }}
                      >
                        Support & Beratung inklusive
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <div style={{ marginBottom: "15px" }}>
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#2d3748",
                      marginBottom: "8px",
                    }}
                  >
                    Jetzt Demo anfragen
                  </h3>
                  <p
                    style={{
                      color: "#4a5568",
                      lineHeight: "1.6",
                      marginBottom: "8px",
                    }}
                  >
                    <strong>Andreas Wolf</strong>
                    <br />
                    Wolf Code Software Solutions
                    <br />
                    Markgrafenstr. 21, 96364 Marktrodach
                    <br />
                    Tel: 0152 52326474
                    <br />
                    <a
                      href="mailto:info@gastroempire.de"
                      style={{ color: "#2b6cb0", textDecoration: "none" }}
                    >
                      E-Mail: info@gastroempire.de
                    </a>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <QRCodeSVG
                    value="https://gastroempire.de"
                    size={120}
                    level="H"
                    includeMargin={true}
                  />
                  <p
                    style={{
                      marginTop: "8px",
                      color: "#2b6cb0",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    www.gastroempire.de
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Rechtes Panel: Front Cover */}
          <div style={panelStyle}>
            <h1
              style={{
                ...headingStyle,
                textAlign: "center",
                fontSize: "38px",
                marginTop: "0",
                marginBottom: "25px",
                color: "#1a365d",
                lineHeight: "1.2",
              }}
            >
              Revolutionieren Sie
              <br />
              Ihr Restaurantgeschäft
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "25px",
              }}
            >
              <img
                src={logo}
                alt="GastroEmpire Logo"
                style={{ width: "400px", height: "auto" }}
              />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                color: "#4A5568",
                marginBottom: "40px",
                fontWeight: "500",
                lineHeight: "1.4",
              }}
            >
              Ihre All-in-One-Lösung für
              <br />
              Online-Bestellungen in der Gastronomie
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "fit-content" }}>
                <img
                  src="/images/Handyansicht2.webp"
                  alt="Mobile Bestellansicht"
                  style={{
                    height: "380px",
                    marginBottom: "0",
                    objectFit: "contain",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
                  }}
                />
              </div>
            </div>
          </div>
          <div style={verticalLine} />
        </div>
      </div>

      {/* Zweite Seite - Innenseiten */}
      <div style={{ ...commonStyles }} className="page-background">
        <div style={{ display: "flex", height: "100%" }}>
          {/* Linkes Panel: Inside Left */}
          <div style={panelStyle}>
            <h2 style={headingStyle}>Alles aus einer Hand</h2>
            <h3 style={subHeadingStyle}>Professionelle Bestellabwicklung</h3>
            <img
              src="/images/Bestellverwaltung.webp"
              alt="Bestellverwaltung"
              style={{ ...imageStyle, marginBottom: "15px" }}
            />
            <ul style={{ listStyle: "none", padding: 0, marginBottom: "25px" }}>
              <li
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <span style={checkmarkStyle}>✓</span>
                <div>
                  <strong style={{ color: "#2d3748" }}>
                    Automatischer Thermodruck
                  </strong>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#4a5568",
                      margin: "2px 0 0 0",
                    }}
                  >
                    Bestellungen werden sofort & automatisch ausgedruckt
                  </p>
                </div>
              </li>
              <li
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <span style={checkmarkStyle}>✓</span>
                <div>
                  <strong style={{ color: "#2d3748" }}>
                    Intelligente Verwaltung
                  </strong>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#4a5568",
                      margin: "2px 0 0 0",
                    }}
                  >
                    Übersichtliches Dashboard für alle Bestellungen
                  </p>
                </div>
              </li>
              <li
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <span style={checkmarkStyle}>✓</span>
                <div>
                  <strong style={{ color: "#2d3748" }}>
                    Paypalintegration für Onlinezahlung
                  </strong>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#4a5568",
                      margin: "2px 0 0 0",
                    }}
                  >
                    Kunden können Bestellungen direkt mit PayPal bezahlen
                  </p>
                </div>
              </li>
              <li
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <span style={checkmarkStyle}>✓</span>
                <div>
                  <strong style={{ color: "#2d3748" }}>
                    Automatische Bestellannahme & Zeitinfo
                  </strong>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#4a5568",
                      margin: "2px 0 0 0",
                    }}
                  >
                    Sofortige Annahme mit individueller Lieferzeitinfo
                  </p>
                </div>
              </li>
            </ul>

            <h3 style={subHeadingStyle}>Flexible Lieferoptionen</h3>
            <img
              src="/images/Lieferzonen.webp"
              alt="Lieferzonen"
              style={{ ...imageStyle, marginBottom: "15px" }}
            />
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <span style={checkmarkStyle}>✓</span>
                <div>
                  <strong style={{ color: "#2d3748" }}>
                    Individuelle Lieferzonen
                  </strong>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#4a5568",
                      margin: "2px 0 0 0",
                    }}
                  >
                    Mit verschiedenen Mindestbestellwerten
                  </p>
                </div>
              </li>
            </ul>
          </div>

          {/* Rechtes Panel: Inside Right */}
          <div style={panelStyle}>
            <h2 style={headingStyle}>Ihr Erfolg ist unser Ziel</h2>
            <h3 style={subHeadingStyle}>Detaillierte Auswertungen</h3>
            <img
              src="/images/Statistiken.webp"
              alt="Statistiken"
              style={{ ...imageStyle, marginBottom: "15px" }}
            />
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <span style={checkmarkStyle}>✓</span>
                <div>
                  <strong style={{ color: "#2d3748" }}>Umsatzanalyse</strong>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#4a5568",
                      margin: "2px 0 0 0",
                    }}
                  >
                    Tägliche, wöchentliche und monatliche Auswertungen
                  </p>
                </div>
              </li>
            </ul>

            <div
              style={{
                backgroundColor: "#EBF8FF",
                padding: "15px",
                borderRadius: "8px",
                marginBottom: "15px",
                border: "1px solid #BEE3F8",
              }}
            >
              <h3
                style={{
                  color: "#2B6CB0",
                  fontSize: "20px",
                  marginBottom: "8px",
                }}
              >
                Jetzt kostenlos durchstarten
              </h3>
              <p
                style={{
                  color: "#4A5568",
                  fontSize: "15px",
                  marginBottom: "10px",
                }}
              >
                2 Monate kostenlose Testphase mit allen Features*
              </p>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li
                  style={{
                    marginBottom: "6px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={checkmarkStyle}>✓</span>
                  Komplette Einrichtung
                </li>
                <li
                  style={{
                    marginBottom: "6px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={checkmarkStyle}>✓</span>
                  WLAN-Thermodrucker
                </li>
                <li
                  style={{
                    marginBottom: "6px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={checkmarkStyle}>✓</span>
                  Paypalunterstützung
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <span style={checkmarkStyle}>✓</span>
                  Technischer Support
                </li>
              </ul>
              <p
                style={{
                  fontSize: "10px",
                  color: "#718096",
                  marginTop: "8px",
                  fontStyle: "italic",
                }}
              >
                * PayPal: Wenn Sie möchten, können Ihre Kunden bequem per PayPal
                online bezahlen. Dafür benötigen Sie ein eigenes PayPal-Konto.
                Die Gebühren für PayPal-Zahlungen werden direkt zwischen Ihnen
                und PayPal abgerechnet.
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <img
                src="/images/IndividuellGestaltbar.webp"
                alt="Individuell gestaltbar"
                style={{ ...imageStyle, height: "100px" }}
              />
              <p
                style={{ fontSize: "14px", color: "#4a5568", marginTop: "8px" }}
              >
                <strong>100% individuell an Ihr Restaurant angepasst</strong>
              </p>
            </div>
          </div>
          <div style={verticalLine} />
        </div>
      </div>
    </div>
  );
});

PrintableContent.displayName = "PrintableContent";

const Flyer = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "GastroEmpire-Flyer",
    pageStyle: "@page { size: A4 landscape; margin: 0mm; }",
    onBeforeGetContent: () => {
      console.log("[Debug] Preparing content...");
      console.log("[Debug] componentRef:", componentRef);
      console.log("[Debug] componentRef.current:", componentRef.current);
      return Promise.resolve();
    },
    onAfterPrint: () => {
      console.log("[Debug] Printed successfully!");
    },
    onPrintError: (error) => {
      console.error("[Debug] Failed to print:", error);
    },
  });

  return (
    <div className="max-w-[1200px] mx-auto p-8">
      <div className="flex flex-col items-center gap-4 mb-8 no-print">
        <button
          onClick={handlePrint}
          className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Flyer drucken
        </button>
        <p className="text-sm text-gray-600">
          Tipp: Wählen Sie beim Drucken "Querformat" und "Ohne Ränder" für das
          beste Ergebnis
        </p>
      </div>

      <div className="space-y-8">
        <div className="border rounded-lg p-4 bg-gray-50 no-print">
          <h3 className="text-lg font-semibold mb-2 text-gray-700">
            Vorschau - Druckbogen
          </h3>
          <p className="text-sm text-gray-600 mb-4">
            Faltanleitung: Die Seiten werden in der Mitte vertikal geteilt.
            Links ist die Vorderseite, rechts die Rückseite. Die zweite Seite
            zeigt die Innenseiten des gefalteten Flyers.
          </p>
        </div>

        <div
          className="print-content"
          style={{ transform: "scale(0.7)", transformOrigin: "top center" }}
        >
          <PrintableContent ref={componentRef} />
        </div>
      </div>

      <style jsx global>{`
        @media print {
          body {
            margin: 0;
            padding: 0;
          }
          .no-print {
            display: none !important;
          }
          .print-content {
            transform: none !important;
          }
        }
        .page-background {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZCIgeDI9IjEwMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdHlsZT0ic3RvcC1jb2xvcjojZjBmN2ZmO3N0b3Atb3BhY2l0eToxIiAvPjxzdG9wIG9mZnNldD0iNTAlIiBzdHlsZT0ic3RvcC1jb2xvcjojZmZmZmZmO3N0b3Atb3BhY2l0eToxIiAvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3R5bGU9InN0b3AtY29sb3I6I2YwZjdmZjtzdG9wLW9wYWNpdHk6MSIgLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPg==");
          background-size: 100% 100%;
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      `}</style>
    </div>
  );
};

export default Flyer;
